<template>
  <div class="container-fluid page-permission-type">
    <Header />

    <div class="mx-auto mt-1 content-outer-container">
      <div class="mx-auto content-inner-container border-header pt-3 pb-3 px-3">
        <div class="col-12 title-header">
          <div @click="goto('UserPermission')">
            <img
              class="button-arrow-back mr-3"
              src="@/assets/images/userlevel/arrow_back.svg"
              alt=""
            />
          </div>
          {{ lbl['permission-type-btn'] }}
        </div>
        <div class="col-12 mt-4">
          <div class="title-detail">
            {{
              lbl['permission-understand-more-clearly-with-permission-and-role']
            }}
          </div>
          <div class="sub-title-detail mt-1">{{ lbl['permission-roles'] }}</div>

          <div class="row mt-4 d-flex align-end">
            <div
              class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-2"
              v-for="(item, index) in permission"
              :key="index"
            >
              <div class="box-permission" :class="'permission-' + item.type">
                <div class="title-type text-capitalize">
                  <img :src="item.image_url" alt="" class="mr-1" />
                  {{ item.type }}
                </div>
                <div class="permission-detail mt-1">
                  {{ lbl[item.detail] }}
                </div>
                <div
                  class="permission-list mt-2 d-flex align-center"
                  v-for="(data, index_data) in item.data"
                  :key="index_data"
                >
                  <img
                    src="@/assets/images/selfonboard/permission-check.svg"
                    alt=""
                    class="mr-1"
                  />
                  {{ lbl[data.key_local] }}
                </div>
                <div class="mt-2">
                  <a-tag
                    v-for="(tag, index_tag) in item.tag"
                    :key="index_tag"
                    class="mb-1"
                  >
                    {{ lbl[tag.key_local] }}</a-tag
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4 collapse-preparing">
            <div class="col-12">
              <a-collapse>
                <a-collapse-panel
                  key="1"
                  :header="lbl['preparing-user-access-by-permission']"
                >
                  <template #extra>
                    <img
                      class="arrowdown"
                      src="@/assets/images/Angle-down.svg"
                      alt=""
                    />
                  </template>
                  <table class="table">
                    <tr>
                      <th>{{ lbl['permission-type-menu'] }}</th>
                      <th class="table-status text-center">
                        {{ lbl['permission-type-owner'] }}
                      </th>
                      <th class="table-status text-center">
                        {{ lbl['permission-type-admin'] }}
                      </th>
                      <th class="table-status text-center">
                        {{ lbl['permission-type-operator'] }}
                      </th>
                      <th class="table-status text-center">
                        {{ lbl['permission-type-staff'] }}
                      </th>
                      <th class="table-description">
                        {{ lbl['plan-reward-point-earn-manage-description'] }}
                      </th>
                    </tr>
                    <tr v-for="(item, index) in preparing" :key="index">
                      <td
                        v-if="item.colspan > 1"
                        :colspan="item.colspan"
                        :class="{ 'table-colspan': item.colspan > 1 }"
                      >
                        {{ lbl[item.key_local] }}
                      </td>
                      <td v-if="item.colspan == 1" :colspan="item.colspan">
                        {{ lbl[item.key_local] }}
                      </td>
                      <td
                        v-if="item.colspan == 1"
                        :colspan="item.colspan"
                        class="text-center"
                      >
                        <img
                          v-if="item.is_owner"
                          src="@/assets/images/selfonboard/permission-pass.svg"
                          alt=""
                        />
                        <img
                          v-else
                          src="@/assets/images/selfonboard/permission-close.svg"
                          alt=""
                        />
                      </td>
                      <td
                        v-if="item.colspan == 1"
                        :colspan="item.colspan"
                        class="text-center"
                      >
                        <img
                          v-if="item.is_admin"
                          src="@/assets/images/selfonboard/permission-pass.svg"
                          alt=""
                        />
                        <img
                          v-else
                          src="@/assets/images/selfonboard/permission-close.svg"
                          alt=""
                        />
                      </td>
                      <td
                        v-if="item.colspan == 1"
                        :colspan="item.colspan"
                        class="text-center"
                      >
                        <img
                          v-if="item.is_operator"
                          src="@/assets/images/selfonboard/permission-pass.svg"
                          alt=""
                        />
                        <img
                          v-else
                          src="@/assets/images/selfonboard/permission-close.svg"
                          alt=""
                        />
                      </td>
                      <td
                        v-if="item.colspan == 1"
                        :colspan="item.colspan"
                        class="text-center"
                      >
                        <img
                          v-if="item.is_staff"
                          src="@/assets/images/selfonboard/permission-pass.svg"
                          alt=""
                        />
                        <img
                          v-else
                          src="@/assets/images/selfonboard/permission-close.svg"
                          alt=""
                        />
                      </td>
                      <td v-if="item.colspan == 1" :colspan="item.colspan">
                        {{ lbl[item.detail_local] }}
                      </td>
                    </tr>
                  </table>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Config from '@/helper/Config.js'
import Header from '@/components/Layout/Header'

export default {
  name: 'PermissionType',
  mixins: [Mixin],
  components: {
    Header,
  },
  data() {
    return {
      permission: [],
      preparing: [],
    }
  },
  created() {
    this.init()
  },
  beforeDestroy() {
    this.permission = []
  },
  methods: {
    init() {
      Config.getConfigPermission().then(res => {
        if (res) {
          this.permission = res.permission
          this.preparing = res.preparing
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/style/main.scss';
@import '@/style/base/common.scss';
.page-permission-type {
  padding-bottom: 100px !important;

  .collapse-preparing {
    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      display: none;
    }

    .ant-collapse {
      background-color: transparent;
      border: initial;
    }

    .ant-collapse > .ant-collapse-item {
      border: initial;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.15px;
      text-align: left;
      color: #424242;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding-left: 0;
      display: flex;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      align-items: center;
    }

    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-extra {
      margin-left: 10px;
    }

    .ant-collapse-content {
      overflow-x: auto;
    }

    .arrowdown {
      width: 28px;
      &:hover {
        filter: invert(73%) sepia(83%) saturate(700%) hue-rotate(353deg)
          brightness(98%) contrast(88%);
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-extra {
        transform: rotate(180deg);
      }
    }

    .ant-collapse-content {
      border-top: initial;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }

    .table {
      & th {
        background-color: $color-grey-42;
        border: 1px solid $color-white;
        color: $color-white;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
      }
      & td {
        border: solid 1px $color-grey-e4;
        background-color: $color-white;
        color: $text-dark;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;

        &.table-colspan {
          background-color: #eff2f5;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .table-status {
        width: 6%;
      }

      .table-description {
        width: 40%;
      }
    }
  }

  .box-permission {
    padding: 15px 10px;
    border-radius: 12px;
    &.permission-owner {
      background-color: $color-orange-7e;
    }
    &.permission-admin {
      background-color: $color-orange-be;
    }
    &.permission-operator {
      background-color: $color-blue-e6;
    }
    &.permission-staff {
      background-color: $color-pink-f6;
    }
    .title-type {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: $text-dark;
    }
    .permission-detail {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: $text-dark-gray;
    }
  }

  .title-header {
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 24px;
    color: $text-dark;
  }

  .button-arrow-back {
    border: solid 1px $color-grey-75;
    border-radius: 8px;
    padding: 8px 20px;
    align-items: center;
    justify-content: end;
    float: right;
    cursor: pointer;
    width: 65px;
  }

  .title-detail {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.15px;
    text-align: left;
    color: $color-grey-42;
  }

  .sub-title-detail {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    text-align: left;
    color: $color-grey-61;
  }
}
</style>